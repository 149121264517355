var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "mt-4" },
    [
      _c("h4", [_vm._v("list task")]),
      _vm._v(" "),
      _c("hr", { staticClass: "w-50 m-auto mb-3" }),
      _vm._v(" "),
      _vm._l(_vm.items, function (item) {
        return _c(
          "div",
          {
            key: item.id,
            staticClass: "card shadow-none mt-2",
            attrs: { item: item },
            on: {
              itemchanged: function ($event) {
                return _vm.$emit("reloadlist")
              },
            },
          },
          [
            _c("div", { staticClass: "card-body p-2" }, [
              _c("div", { staticClass: "task_title text-primary" }, [
                _vm._v(
                  "\n                " + _vm._s(item.libelle) + "\n            "
                ),
              ]),
              _vm._v(" "),
              _vm._m(0, true),
              _vm._v(" "),
              _vm._m(1, true),
            ]),
          ]
        )
      }),
      _vm._v(" "),
      _c(
        "ul",
        { staticClass: "list-group m-auto" },
        _vm._l(_vm.items, function (item) {
          return _c("list-item", {
            key: item.id,
            staticClass: "m-auto my-1 text-justify text-wrap",
            attrs: { item: item },
            on: {
              itemchanged: function ($event) {
                return _vm.$emit("reloadlist")
              },
            },
          })
        }),
        1
      ),
    ],
    2
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "task_date" }, [
      _c("i", { staticClass: "fa fa-clock" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "row mt-1 justify-content-between" }, [
      _c("div", { staticClass: "col-6" }, [
        _c("select", {
          staticClass: "form-control",
          attrs: { name: "status", id: "changeStatus" },
        }),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-6 text-right" }, [
        _c("i", { staticClass: "btn btn-sm btn-light fa fa-eye" }),
        _vm._v(" "),
        _c("i", {
          staticClass: "btn btn-sm btn-light fa fa-edit editModal",
          staticStyle: { cursor: "pointer" },
          attrs: { title: "Modifier la tâche" },
        }),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }